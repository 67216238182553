@import 'src/Spot/Spot';

.enrollment-submit-eula-container {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .enrollment-submit-eula-text {
    .searching-content-title {
      padding-top: 40px;
      font-size: $spot-font-size-header-level-1;
      font-weight: $spot-font-weight-light;
      margin: auto;
      max-width: 800px;
      padding-right: 25px;
      padding-left: 25px;
    }

    .searching-spinner {
      padding-top: 25px;
    }
  }

  .enrollment-submit-eula-footer {
    height: 97px;
    margin-bottom: 66px;
  }

  @media screen and (max-width: 599px) and (min-width: 0px) {
    .enrollment-submit-eula-footer {
      position: fixed;
      bottom: 0px;
      margin-bottom: 0px;
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) and (min-width: 600px) {
    .enrollment-submit-eula-footer {
      position: fixed;
      bottom: 0px;
      margin-bottom: 0px;
      width: 100%;
    }
  }
}

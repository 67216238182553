@import '../../../Spot/Spot.scss';
.enrollment-left-pane-container {
  background-color: $spot-color-teal-35;
  height: 100%;
  width: 100%;
  color: white;
  font-weight: lighter;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .enrollment-left-pane-welcome {
    font-size: $spot-font-size-header-level-4;
    text-align: left;
    padding-top: 20px;
    font-weight: bold;
  }
  .enrollment-left-pane-get-started {
    position: relative;
    font-size: 34px;
    float: left;
    text-align: left;
  }

  .enrollment-left-pane-skip {
    position: relative;
    float: left;
    padding-top: 40px;
    font-size: 15px;
    width: 100%;
    text-align: left;
    a {
      text-decoration: none;
      color: white;
      cursor: pointer;
    }

    svg {
      fill: white;
      margin: auto;
      display: block;
    }
  }

  .enrollment-left-pane-need-help {
    text-align: left;
    padding-bottom: 50px;
    padding-top: 15px;
    font-size: 15px;
  }

  .enrollment-left-pane-phone-number {
    padding-top: 5px;
    font-weight: bold;
    a {
      text-decoration: none;
      color: white;
    }
  }
}

@media screen and (max-width: 599px) and (min-width: 0px) {
  .enrollment-left-pane-container {
    padding-left: 32px;
    justify-content: start;
    height: auto;
  }

  .enrollment-left-pane-welcome {
    display: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 0px) {
  .enrollment-left-pane-container {
    padding-left: 32px;
    justify-content: start;
    height: auto;
    .enrollment-left-pane-get-started {
      font-size: 18px;
      padding-top: 30px;
    }

    .enrollment-left-pane-skip {
      padding-top: 10px;
    }
  }

  .enrollment-left-pane-welcome {
    display: none;
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .enrollment-left-pane-container {
    padding: 32px 52px 32px 34px;
  }
}

@media screen and (max-width: 1439px) and (min-width: 1024px) {
  .enrollment-left-pane-container {
    padding: 46px 32px 34px 46px;
  }
}

@media screen and (min-width: 1440px) {
  .enrollment-left-pane-container {
    padding: 60px 68px 34px 68px;
  }
}

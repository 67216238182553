.enrollment-header-container {
  width: 100%;
  height: 100%;
  padding-left: 64px;
  padding-top: 16px;
  padding-bottom: 13px;
  .enrollment-header-logo {
    width: 150px;
  }
}

@media screen and (max-width : 599px) and (min-width : 0px){
  .enrollment-header-container {
    padding-left: 32px;
    padding-top: 12px;
    padding-bottom: 9px;
    .enrollment-header-logo {
      width: 100px;
    }
  }
}

@media screen and (max-width : 767px) and (min-width : 600px){
  .enrollment-header-container {
    padding-left: 32px;
    padding-top: 12px;
    padding-bottom: 9px;
    .enrollment-header-logo {
      width: 100px;
    }
  }
}

@media screen and (max-width : 1023px) and (min-width : 768px){
  .enrollment-header-container {
    padding-left: 32px;
    .enrollment-header-logo {
      width: 150px;
    }
  }
}

@media screen and (max-width : 1439px) and (min-width : 1024px){
  .enrollment-header-container {
    padding-left: 46px;
    .enrollment-header-logo {
      width: 150px;
    }
  }
}
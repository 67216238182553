@import '../../../Spot/Spot.scss';

.enrollment-right-pane-container {
  position: relative;
  height: 100%;

  .enrollment-right-pane-header {
    background-color: $spot-color-teal-35;
    height: 5px;
  }

  .loading-spinner-container {
    display: flex;
    height: 75%;
    align-items: center;

    svg {
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 599px) and (min-width: 0px) {
    .enrollment-right-pane-header {
      display: none;
    }
  }

  @media screen and (max-width: 767px) and (min-width: 600px) {
    .enrollment-right-pane-header {
      display: none;
    }
  }
}

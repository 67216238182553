@import '/src/Spot/Spot.scss';

.enrollment-eula-confirmation-footer-container {
  background-image: linear-gradient(-45deg, #33BFCC 0%, #00A7B5 100%);
  position: relative;
  height: 100%;
  min-width: 400px;
  text-align: -webkit-center;
  text-align: -moz-center;

  .confirmation-content-container {
    color: white;
    font-size: 24px;
    text-align: center;
    max-width: 600px;
      .confirmation-title {
        font-weight: bold;
        padding: 32px 32px 0px 32px;
      }

      .confirmation-text {
        padding: 32px 32px 32px 32px;
      }

      .enrollment-eula-confirmation-footer-button-container {
        margin: 0;
        padding-top: 32px;
        transform: translateY(-50%);
        .confirmation-arrow-button {
          color: white;
          width: 32px;
          height: 32px;
          transform: rotate(180deg);
          opacity: 0.4;
          cursor: pointer;
        }

        .enrollment-eula-confirmation-footer-button {
          padding: 10px 22px 10px 22px;
          font-size: 18px;
          background-color: black;
          color: white;
          border-radius: 5px;
          border: none;
          box-shadow: 1px 1px 6px 0 rgba(60, 0, 0, 0.34);
          font-family: 'Open Sans', 'Helvetica Neue', 'sans-serif';
          cursor: pointer;
        }
      }

    @media screen and (max-width: 767px) and (min-width: 0px) {
      .enrollment-eula-confirmation-footer-button-container {
        margin: 0;
        position: relative;
        top: 45px;
        transform: translateY(-50%);
        padding: 26px;
      }
    }
  }

}
@import 'src/Spot/Spot';

.container {
  height: 100vh;
  display: flex;
  align-items: center;
}

.text {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $spot-color-neutral-48;
  font-size: $spot-font-size-l;
}

.spinnerContainer {
  margin: 2px;
}

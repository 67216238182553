@import '../../Spot/Spot.scss';

svg.header-logo {
    height: 100%;
    width: 100%;
    .cls-1 {
        fill: $spot-color-neutral-60;
    }
    .cls-2 {
        fill: $spot-color-red-60;
    }
}

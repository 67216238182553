@import '/src/Spot/Spot.scss';

.enrollment-eula-footer-container {
  background-color: $spot-color-neutral-91;
  height: 100%;
  min-width: 400px;

  .enrollment-eula-footer-button-container {
    margin: 0;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    padding: 26px;
    .enrollment-eula-footer-button {
      padding: 10px 22px 10px 22px;
      font-size: 20px;
      background-color: $spot-color-teal-35;
      color: white;
      border-radius: 5px;
      border: none;
      box-shadow: 1px 1px 6px 0 rgba(60, 0, 0, 0.34);
      font-family: 'Open Sans', 'Helvetica Neue', 'sans-serif';
      cursor: pointer;
    }

    .enrollment-eula-footer-secondary-button {
      background-color: $spot-color-neutral-91;
      font-size: 18px;
      color: $spot-color-teal-35;
      cursor: pointer;
      position: absolute;
      padding-top: 10px;
    }
  }

  @media screen and (max-width: 767px) and (min-width: 0px) {
    .enrollment-eula-footer-button-container {
      margin: 0;
      position: relative;
      top: 45px;
      transform: translateY(-50%);
      padding: 26px;
    }
  }
}

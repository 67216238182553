@import 'src/Spot/Spot';

.confirmation-page-fade {
  //opacity: 0;
  //position: fixed;
  //top: 0;
  //left: 0;
  //height: 100%;
  //width: 100%;
  //background: url(http://studi0.ml/EzJsucI.jpg) no-repeat center center fixed;
  //background-size: cover;

  animation-name: fadeOut;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 1.3s;
  animation-fill-mode:forwards;
  .enrollment-confirmation-container {

    height: 100%;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .enrollment-confirmation-top {
      position: relative;
      height: 44%;
    }

    .enrollment-confirmation-footer {
      min-height: 275px;
      height: 56%;
    }

    .content-image {
      top: 15%;
      position: relative;
      text-align: center;
      height: 100%;

      .confirmation-image {
        height: 70%;
        max-height: 300px;
      }
    }

    @media screen and (max-width: 1439px) and (min-width: 768px) {
      .enrollment-confirmation-footer {
        min-height: 275px;
        height: 56%;
      }
    }

    @media screen and (min-width: 1440px) {
      .enrollment-confirmation-footer {
        min-height: 275px;
        height: 56%;
      }
    }

    @media screen and (max-width: 767px) and (min-width: 0px) {
      .enrollment-confirmation-footer {
        min-height: 275px;
        position: fixed;
        bottom: 0px;
        margin-bottom: 0px;
        width: 100%;
        height: 56%;
      }
    }
  }
}

.confirmation-page-no-fade {
  .enrollment-confirmation-container {

    height: 100%;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .enrollment-confirmation-top {
      position: relative;
      height: 44%;
    }

    .enrollment-confirmation-footer {
      min-height: 275px;
      height: 56%;
    }

    .content-image {
      top: 15%;
      position: relative;
      text-align: center;
      height: 100%;

      .confirmation-image {
        height: 70%;
        max-height: 300px;
      }
    }

    @media screen and (max-width: 1439px) and (min-width: 768px) {
      .enrollment-confirmation-footer {
        min-height: 275px;
        height: 56%;
      }
    }

    @media screen and (min-width: 1440px) {
      .enrollment-confirmation-footer {
        min-height: 275px;
        height: 56%;
      }
    }

    @media screen and (max-width: 767px) and (min-width: 0px) {
      .enrollment-confirmation-footer {
        min-height: 275px;
        position: fixed;
        bottom: 0px;
        margin-bottom: 0px;
        width: 100%;
        height: 56%;
      }
    }
  }
}
@import '../../../Spot/Spot.scss';

svg.close-icon {
  fill: $spot-color-neutral-60;
  width: $spot-size-icon-l
}

.close-box-container {

  :hover {
    fill: $spot-color-teal-35;
  }
}
@import 'src/Spot/Spot';

.enrollment-call-us-container {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .enrollment-call-us-text {
    text-align: -webkit-center;
    text-align: -moz-center;
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: auto;
    .call-us-title-container {
      max-width: 600px;
      .call-us-title {
        padding-top: 40px;
        font-size: $spot-font-size-header-level-1;
        font-weight: $spot-font-weight-light;
      }
    }

    .content-image {
      padding-top: 26px;
      padding-bottom: 26px;
      .no-results-dog {
        width: 150px;
      }
    }
    .call-us-give-us-a-call-container {
      text-align: -webkit-center;
      text-align: -moz-center;
      .call-us-give-us-a-call-text {
        font-size: $spot-font-size-header-level-5;
        max-width: 300px;
      }
      .call-us-phone-number {
        padding-top: 10px;
        font-weight: bold;
        font-size: $spot-font-size-header-level-4;
        a {
          text-decoration: none;
          color: black;
        }
      }
      .call-us-skip {
        padding-top: 20px;
        a {
          text-decoration: none;
          color: $spot-color-teal-35;
          cursor: pointer;
        }
      }
    }
  }

  .enrollment-call-us-footer {
    height: 97px;
    margin-bottom: 66px;
  }

  @media screen and (max-width: 599px) and (min-width: 0px) {
    .enrollment-call-us-footer {
      position: fixed;
      bottom: 0px;
      margin-bottom: 0px;
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) and (min-width: 600px) {
    .enrollment-call-us-footer {
      position: fixed;
      bottom: 0px;
      margin-bottom: 0px;
      width: 100%;
    }
  }
}

.spot-custom-dropdown {
  .spot-custom-dropdown__control {
    border: 0 !important;
    box-shadow: 0 0 0 0 !important;
    background-color: unset !important;
  }

  .spot-custom-dropdown-inner {
    padding: 0;
    height: unset;
  }
}

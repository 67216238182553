@import 'src/Spot/Spot';

.enrollment-pims-container {
  width: 362px;
  margin: 40px auto auto;
  text-align: left;
}

.enrollment-practice-found-container-scroll {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 40px;
}

.enrollment-practice-found-container {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .enrollment-practice-found-text {
    .searching-content-title {
      padding-top: 40px;
      font-size: $spot-font-size-header-level-1;
      font-weight: $spot-font-weight-light;
      margin: auto;
      max-width: 800px;
      padding-right: 25px;
      padding-left: 25px;
    }

    .content-image {
      padding-top: 26px;
      padding-bottom: 26px;
      .house-image {
        width: 150px;
      }
    }

    .searching-spinner {
      padding-top: 25px;
    }

    .found-practice-name {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.loading-spinner {
  text-align: center;
}

.enrollment-find-practice-footer {
  height: 97px;
  margin-bottom: 66px;
  z-index: 1;
}

.practice-owner {
  padding-top: 15px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.practice-owner-instructions {
  max-width: 400px;
  margin-top: 15px;
}

@media screen and (max-width: 599px) and (min-width: 0px) {
  .enrollment-find-practice-footer {
    position: fixed;
    bottom: 0px;
    margin-bottom: 0px;
    width: 100%;
  }

  .enrollment-practice-found-container-scroll {
    padding-bottom: 160px;
  }

  .enrollment-practice-found-container {
    height: calc(100% - 140px);
  }
}

@media screen and (max-width: 767px) and (min-width: 600px) {
  .enrollment-find-practice-footer {
    position: fixed;
    bottom: 0px;
    margin-bottom: 0px;
    width: 100%;
  }

  .enrollment-practice-found-container-scroll {
    padding-bottom: 160px;
  }

  .enrollment-practice-found-container {
    height: calc(100% - 140px);
  }
}

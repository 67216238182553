@import 'src/Spot/Spot';
.enrollment-find-practice-parent-container {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .enrollment-find-practice-container-scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .enrollment-find-practice-container {
      text-align: -webkit-center;
      text-align: -moz-center;
      position: relative;
      display: flex;
      flex-direction: column;
      height: 0;
      flex-grow: 1;

      .content-title {
        padding-top: 40px;
        font-size: $spot-font-size-header-level-1;
        font-weight: $spot-font-weight-light;
      }

      .content-image {
        padding-top: 26px;
        padding-bottom: 26px;
        .house-image {
          width: 150px;
        }
      }

      .content-input-form-and-text {
        margin-left: auto;
        margin-right: auto;
        text-align: left;
        padding-bottom: 30px;

        .content-input-form-inline-error {
          margin-bottom: 30px;
        }

        .content-input-form {
          text-align: left;

          .help-image-1 {
            position: relative;
            top: -189px;
            left: 210px;

            svg {
              cursor: pointer;
            }
          }

          .help-image-2 {
            position: relative;
            top: -133px;
            left: 326px;

            svg {
              cursor: pointer;
            }
          }

          .help-image-1-show-error {
            position: relative;
            top: -227px;
            left: 210px;

            svg {
              cursor: pointer;
            }
          }

          .help-image-1-show-error-both {
            position: relative;
            top: -275px;
            left: 210px;

            svg {
              cursor: pointer;
            }
          }

          .help-image-1-show-error-bottom {
            position: relative;
            top: -237px;
            left: 210px;

            svg {
              cursor: pointer;
            }
          }

          .help-image-2-show-error {
            position: relative;
            top: -181px;
            left: 326px;

            svg {
              cursor: pointer;
            }
          }
        }

        .content-contact-us {
          text-align: left;
          color: $spot-color-neutral-48;

          a {
            font-weight: bold;
            text-decoration: none;
            color: black;
          }
        }
      }
    }
  }
  .enrollment-find-practice-footer {
    width: 100%;
    margin-bottom: 66px;
  }

  @media screen and (max-width: 599px) and (min-width: 0px) {
    .enrollment-find-practice-container-scroll {
      overflow-y: hidden;
      overflow-x: hidden;
    }
    .enrollment-find-practice-footer {
      position: fixed;
      bottom: 0px;
      margin-bottom: 0px;
    }
    @media screen and (max-width: 400px) and (min-width: 0px) {
      .enrollment-find-practice-footer {
        width: auto;
      }
    }
  }

  @media screen and (max-width: 767px) and (min-width: 600px) {
    .enrollment-find-practice-container-scroll {
      overflow-y: hidden;
      overflow-x: hidden;
    }
    .enrollment-find-practice-footer {
      position: fixed;
      bottom: 0px;
      margin-bottom: 0px;
    }
  }
}

@media screen and (max-width: 599px) and (min-width: 0px) {
  .enrollment-find-practice-parent-container {
    height: calc(100% + 300px);
  }
}

@media screen and (max-width: 767px) and (min-width: 600px) {
  .enrollment-find-practice-parent-container {
    height: calc(100% + 300px);
  }
}

@import '~@idexx/spot/dist/styles/spot_library.css';
@import '~@idexx/spot/dist/styles/shared/spot_tokens';
@import '~@idexx/spot/dist/styles/shared/color';
@import '~@idexx/spot/dist/styles/shared/typography';
@import '~@idexx/spot/dist/styles/shared/util';

.login-layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  .login-route {
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-flow: row wrap;
    padding-top: 50px;
    padding-bottom: 50px;

    @media (min-width: 768px) {
      height: 100vh;
    }

    .iframe {
      width: 400px;
      height: 850px;
      border: none;
    }
  }

  & .left-pane {
    background-color: $spot-interactive-color-on-light;
    width: 100%;
    padding: $spot-space-inset-two;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 768px) {
      width: 30%;
      height: 100vh;
      min-width: 320px;
    }

    @media (min-width: 992px) {
      min-width: 370px;
    }

    @media (min-width: 1200px) {
      padding: $spot-space-inset-four;
    }

    & .need-help-block {
      color: $spot-color-white;
      text-align: left;
      margin-top: 20px;

      & a {
        color: $spot-color-white;
        font-weight: 700;
        padding-left: 20px;

        @media (min-width: 768px) {
          display: block;
          padding-left: 0;
        }
      }
    }

    & .advertisment-block {
      background-color: $spot-color-teal-50;
      padding: $spot-space-inset-one-and-a-half;
      border-radius: 4px;
      text-align: center;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      & img {
        width: 100%;
        max-width: 458px;
      }

      & img.connections-labels {
        display: none;

        @media (min-width: 992px) {
          display: inline;
        }
      }

      & img.connections-no-labels {
        display: none;

        @media (min-width: 768px) {
          display: inline;
        }

        @media (min-width: 992px) {
          display: none;
        }
      }

      & .make-connections {
        color: $spot-color-white;
        font-weight: 300;
        font-size: 32px;
        line-height: 46px;
        text-transform: capitalize;
        margin-top: 0;
        margin-bottom: 0;

        & span {
          display: inline-block;
        }

        @media (min-width: 576px) {
          font-size: 24px;
          line-height: 35px;
        }

        @media (min-width: 768px) {
          margin-top: 16px;
          margin-bottom: 16px;

          & span {
            display: block;
          }
        }

        @media (min-width: 992px) {
          font-size: 32px;
          line-height: 46px;
        }
      }

      & .spot-link {
        color: $spot-color-white;
        text-transform: capitalize;
        text-align: center;
        display: inline-block;
        width: 100%;
        font-size: 18px;
      }
    }
  }

  & .main-content {
    width: 100%;
    @media (min-width: 992px) {
      width: 70%;
    }
  }
}

@import '../Spot';

.spot-form {
    & .form-columns {
        display: flex;
        margin: unset;
        width: 100%;
    }

    & .form-column {
        //flex: 0 0 50%;
        //width: 50%;
    }

    & .form-relative {
        position: relative;
    }

    & .form-column {
        width: 100%;
        border-right: 1px solid #e6e8ea;
        padding-right: 32px;
        padding-left: 32px;
    }

    & .form-column:first-child {
        padding-left: unset;
    }

    & .form-column:last-child {
        padding-right: unset;
        border-right: unset;
    }

    & .lengthy-form-notes {
        max-width: none;
    }

    & .spot-long-form-text .lengthy-form-heading {
        margin: unset;
    }
    & legend {
        border-bottom: 0;
        width: unset;
    }
    & .image-upload-center {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
    }
    & .image-aspect-outer {
        position: relative;
        overflow: hidden;
    }
    & .image-aspect-inner {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    & .image-hover-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
        opacity: 0.4;
        cursor: pointer;
        &:hover {
            background: $spot-interactive-color-hover-on-light-secondary;
            & .image-upload-icon {
                fill: $spot-interactive-color-hover-on-light-secondary;
            }
        }
    }
    & .image-upload-icon {
        width: 50px;
        height: 50px;
        z-index: 3;
        fill: $spot-interactive-color-on-light;
        pointer-events: none;
    }

    & .form-blur-confirmation {
        position: absolute;
        top: 100%;
        right: 20px;
        opacity: 0;
        font-size: 12px;
    }
    & .form-blur-confirmation--visible {
        @extend .form-blur-confirmation;
        transition: opacity 0.5s ease-out, right 0.25s ease-out;
        -webkit-transition: opacity 0.5s ease-out, right 0.25s ease-out;
        -moz-transition: opacity 0.5s ease-out, right 0.25s ease-out;
        -o-transition: opacity 0.5s ease-out, right 0.25s ease-out;
        opacity: 1;
        right: 0;
    }
    & .form-blur-confirmation--successful {
        @extend .form-blur-confirmation--visible;
        color: $spot-color-green-58;
    }
    & .form-blur-confirmation--failed {
        @extend .form-blur-confirmation--visible;
        color: $spot-color-red-60;
    }
    & img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    & .spot-form__input--disabled {
        pointer-events: none;
    }
}

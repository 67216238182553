.spot-popover-wrapper {
    display: inline-block;
    overflow: visible;
    position: relative;
}

.spot-popover-hide {
    visibility: hidden;
}

.spot-popover--top-far-left {
    bottom: 100%;
    left: 0%;
    transform: translateX(-100%) translateX(23px) translateY(-16px);
}

.spot-popover--top-far-left::before {
    bottom: -7px;
    right: 16px;
    transform: rotate(225deg);
}

.spot-popover--top-far-right {
    bottom: 100%;
    right: 100%;
    transform: translateX(100%) translateX(-23px) translateY(-16px);
}

.spot-popover--top-far-right::before {
    bottom: -7px;
    left: 16px;
    transform: rotate(225deg);
}

.spot-popover--bottom-far-left {
    right: 100%;
    top: 100%;
    transform: translateX(23px) translateY(16px);
}

.spot-popover--bottom-far-left::before {
    right: 16px;
    top: -7px;
    transform: rotate(45deg);
}

.spot-popover--bottom-far-right {
    left: 0%;
    top: 100%;
    transform: translateX(-23px) translateY(16px);
}

.spot-popover--bottom-far-right::before {
    left: 16px;
    top: -7px;
    transform: rotate(45deg);
}

.spot-popover--full-screen {
    position: fixed;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
}

.spot-popover--full-screen::before {
    display: none;
}

.popover-close-box {
    height:10px;
    float: right;
}

.full-screen-children {
    padding: 25px;
}

/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

@import './Spot/Spot.scss';

// Download OpenSans font for light 300, regular 400, and bold 700
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap');

body {
  margin: 0;
  padding: 0;
  background: $spot-background-color-light-primary;
  font-family: 'Open Sans', 'Helvetica Neue', 'sans-serif';
  overflow:hidden;
@include spot-box-sizing();
}

html {
  overflow:hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

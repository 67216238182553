@import '../../../Spot/Spot.scss';

.enrollment-searching-for-practice-container {
  text-align: center;
}

.enrollment-main-container {
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;

  .enrollment-header {
    display: flex;
  }

  .enrollment-main-content {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    min-width: 400px;

    .enrollment-left-pane {
      position: relative;
      float: left;
      height: 100%;
      width: 28%;
      min-height: 488px;
      min-width: 300px;
    }
    .enrollment-right-pane {
      height: 100%;
      width: 100%;
    }

    @media screen and (max-width: 599px) and (min-width: 0px) {
      .enrollment-left-pane {
        display: contents;
        vertical-align: top;
        height: fit-content;
        width: 100%;
        float: none;
        min-width: 320px;
        min-height: 320px;
      }

      .enrollment-right-pane {
        width: 100%;
      }
    }

    @media screen and (max-width: 767px) and (min-width: 600px) {
      .enrollment-left-pane {
        display: contents;
        vertical-align: top;
        height: fit-content;
        width: 100%;
        float: none;
        min-width: 320px;
        min-height: 320px;
      }

      .enrollment-right-pane {
        width: 100%;
      }
    }

    @media screen and (max-width: 1023px) and (min-width: 768px) {
      .enrollment-left-pane {
        width: 260px;
        min-width: 260px;
      }
    }

    @media screen and (max-width: 1439px) and (min-width: 1024px) {
      .enrollment-left-pane {
        width: 28%;
        min-width: 300px;
      }
    }

    @media screen and (min-width: 1440px) {
      .enrollment-left-pane {
        width: 28%;
        min-width: 28%;
      }
    }
  }
}

@media screen and (max-width: 599px) and (min-width: 0px) {
  .enrollment-main-container {
    .enrollment-main-content {
      overflow-y: auto;
      overflow-x: hidden;
      display: block;
    }
  }
}

@media screen and (max-width: 767px) and (min-width: 600px) {
  .enrollment-main-container {
    .enrollment-main-content {
      overflow-y: auto;
      overflow-x: hidden;
      display: block;
    }
  }
}

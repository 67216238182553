@import '../../../../../Spot/Spot.scss';

.enrollment-help {
  width: 100%;
  .enrollment-help-image {
    width: 100%;
  }

  .enrollment-help-text {
    width: 100%;
    font-size: $spot-font-size-m;
    color: $spot-color-neutral-30;
    padding-bottom: 16px;
  }
}
@import 'src/Spot/Spot';

.enrollment-technical-contact-parent-container {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .enrollment-technical-contact-container-scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .enrollment-technical-contact-container {
      text-align: -webkit-center;
      text-align: -moz-center;
      position: relative;
      display: flex;
      flex-direction: column;
      height: max-content;
      flex-grow: 1;

      .technical-contact-content-title-container {
        width: 100%;
        .technical-contact-content-title {
          text-align: center;
          max-width: 600px;
          padding-top: 40px;
          font-size: $spot-font-size-header-level-1;
          font-weight: $spot-font-weight-light;
        }
      }

      .content-image {
        padding-top: 26px;
        padding-bottom: 26px;
        .house-image {
          width: 150px;
        }
      }

      .content-input-form-and-text {
        margin-left: auto;
        margin-right: auto;
        text-align: left;
        padding-bottom: 30px;

        .content-input-form {
          text-align: left;
          width: 360px;
        }
      }
    }
  }

  .enrollment-technical-contact-footer {
    width: 100%;
    margin-bottom: 66px;
  }

  @media screen and (max-width: 400px) and (min-width: 0px) {
    .enrollment-technical-contact-footer {
      width: auto;
    }
  }

  @media screen and (max-width: 767px) and (min-width: 0px) {
    .enrollment-technical-contact-footer {
      position: fixed;
      bottom: 0px;
      margin-bottom: 0px;
    }
  }
}

@media screen and (max-width: 767px) and (min-width: 0px) {
  .enrollment-technical-contact-parent-container {
    height: auto;

    .enrollment-technical-contact-container-scroll {
      margin-bottom: 160px;
      overflow-y: hidden;
      overflow-x: hidden;
    }
  }
}

@import 'src/Spot/Spot';

.content-input-form-title {
  font-weight: bold;
  text-align: left;
  margin: 40px 0 20px 0;
  padding: 10px 26px 10px 26px;
}

.eula-question-link {
  color: #61dafb;
}

.eula-modal {
  .spot-modal {
    margin-top: 5vh;
  }
}

.eula-text-content {
  white-space: pre-wrap;
  max-width: 768px;
  text-align: left;
  padding: 24px;
}

.enrollment-eula-parent-container {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .enrollment-eula-container-scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .enrollment-eula-container {
      text-align: -webkit-center;
      text-align: -moz-center;
      position: relative;
      display: flex;
      flex-direction: column;
      height: max-content;
      flex-grow: 1;

      .eula-content-title-container {
        width: 100%;

        .eula-content-title {
          text-align: left;
          max-width: 768px;
          padding-top: 40px;
          padding-left: 24px;
          font-size: $spot-font-size-header-level-1;
          font-weight: $spot-font-weight-light;
        }
      }
    }
  }

  @media screen and (max-width: 767px) and (min-width: 0px) {
    .enrollment-eula-container-scroll {
      margin-bottom: 740px;
      overflow-y: hidden;
      overflow-x: hidden;
    }
  }

  .enrollment-eula-footer {
    width: 100%;
    margin-bottom: 66px;
  }

  @media screen and (max-width: 767px) and (min-width: 0px) {
    .enrollment-eula-footer {
      position: fixed;
      bottom: 0;
      margin-bottom: 0;
      left: 0;
    }
  }
}

@media screen and (max-width: 767px) and (min-width: 0px) {
  .enrollment-eula-parent-container {
    height: auto;
  }
}

.content-input-form-main {
  margin-left: auto;
  margin-right: auto;
  text-align: -webkit-center;
  max-width: 550px;

  .content-input-form {
    text-align: left;
    max-width: 768px;
    padding: 10px 26px 10px 26px;

    .eula-name-title {
      display: flex;
      flex-direction: column;

      .eula-first-last-name {
        flex: 0 0 70%;
        display: flex;
        flex-direction: row;
        margin-right: 0px;

        .eula-first-name {
          flex: 0 0 50%;
          margin-right: 10px;
        }

        .eula-last-name {
          flex: 0 0 50%;
          flex-basis: calc(50% - 10px);
        }
      }

      .eula-position {
        flex: 0 0 30%;
        padding-bottom: 24px;
      }
    }

    .eula-name-title > div {
      text-align: left;
    }

    @media screen and (max-width: 1023px) and (min-width: 0px) {
      .eula-name-title {
        .eula-first-last-name {
          .eula-last-name {
            margin-bottom: 20px;
          }
          .eula-first-name {
            margin-bottom: 0;
          }
        }
        .eula-position {
          padding-bottom: 20px;
        }
      }
      .eula-email-phone {
        .eula-email {
          margin-bottom: 20px;
        }
        .eula-phone {
          margin-bottom: 20px;
        }
      }
      .checkboxes-container {
        text-align: left;
        padding-bottom: 0;
        width: auto;
      }
    }

    .eula-email-phone {
      display: flex;
      flex-direction: row;
      .eula-email {
        flex-basis: calc(65% - 10px);
      }

      @media screen and (max-width: 1023px) and (min-width: 0px) {
        .eula-email {
          flex-basis: calc(65% - 10px);
        }
      }

      .eula-phone {
        flex: 0 0 35%;
        margin-right: 10px;
      }
    }

    .eula-email-phone > div {
      text-align: left;
    }

    .checkboxes-container {
      text-align: left;
      padding-bottom: 16px;
      width: auto;
    }
    @media screen and (max-width: 767px) and (min-width: 0px) {
      .checkboxes-container {
        padding-bottom: 0px;
      }
    }

    @media screen and (min-width: 1024px) {
      .checkboxes-container {
        width: calc(100% + 20px);
      }
    }
  }
}

.form-column {
    width: 100%;
    border-right: 1px solid #e6e8ea;
    padding-right: 32px;
    padding-left: 32px;
    &:first-child {
        padding-left: unset;
    }
    &:last-child {
        padding-right: unset;
        border-right: unset;
    }
}
